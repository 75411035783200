import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Footer = () => {
  return(
  <FooterStyle>
    <div className='section'>
      <Link to='/' title='About'>About</Link>
      <Link to='/' title='Terms'>Terms</Link>
      <Link to='/' title='Contact'>Contact</Link>
    </div>
    <div className='copy'>
      <p>&copy; 2020 | 8bitduck LLC</p>
    </div>
  </FooterStyle>
  );
}
export default Footer;

const FooterStyle = styled.div`
  border-top: 1px solid #e6e6e6;
  height: auto;
  padding: 20px 0 0 0;
  text-align: left;
  .section {
    display: inline-block;
    height: auto;
    width: auto;
    margin: 0 13px 0 13px;
    text-align: left;
    a {
      display: inline-block;
      color: #2e2e2e;
      margin: 0 13px 10px 13px;
      font-size: 1.1rem;
      text-decoration: none;
      &:after {
        content: '';
        display: block;
        background: #ad1a84;
        height: 3px;
        width: 40%;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }
      &:hover:after {
        transform: scaleX(1);
        transform-origin: 0% 50%;
      }
    }
  }
  .copy {
    height: auto;
    margin: 35px 0 0 0;
    padding: 20px 10px 10px 10px;
    text-align: center;
    p {
      color: #2e2e2e;
      font-size: 1rem;
    }
  }
  @media screen and (min-width: 300px) and (max-width: 600px) {
    .section {
      margin: 0 0 0 0;
    }
    .copy {
      margin: 0 0 0 0;
    }
  }
`;