import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import Footer from './footer'
import "../../../styles/main.css"
import theme from "../../../styles/theme"
import GlobalStyles from "../../../styles/GlobalStyles"

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <main style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, minHeight: '100vh' }}>
          { children }
        </div>
        <Footer />
      </main>
    </>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
